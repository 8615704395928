<template>
    <div class="BusinessCard" style="bottom:0;">
        <div class="screenSearchPop" style="bottom:0">
            <div class="imgBox imgBox1" v-if="imgUrl !== ''">
                <div class="filter"></div>
                <img :src="imgUrl" class="img1" @click="showImg()" :onerror="defaultImg" />
                <div class="clickPhone" @click="scanning()">
                    <i class="van-icon van-icon-photograph"></i>
                </div>
            </div>
            <div class="imgBox imgBox2" v-show="imgUrl == ''">
                <div class="img2">
                    <van-uploader :after-read="onRead" ref="vanUploader">
                        <van-icon name="photograph" />
                    </van-uploader>
                </div>
            </div>
            <!-- 数据解析中...请稍等 -->
            <loading-new :loading="loading" :text="$t('mx_mobile.Client.1585548023204')" style="margin-top:40px"></loading-new>
            <!-- <div v-if="loading" style="width:100%">
        <loading></loading>
        <div class="loadingBox">数据解析中...请稍等</div>
      </div> -->
            <template v-if="!loading&&initState">
                <div v-show="isHave" class="addPeople">
                    <control-vue ref="controlVue" :editSet="editSet2" :optCode="optCode" moduleCode="BF003" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :AONIcustSort="AONIcustSort" :currency="currency"></control-vue>
                </div>
                <div v-show="!isHave" class="addPeople">
                    <control-vue ref="controlVue1" :editSet="editSet" :optCode="optCode" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :AONIcustSort="AONIcustSort" :currency="currency" @custNameChange="custNameChange"></control-vue>
                    <div class="listBox" v-if="editSet1.length!=0">
                        <!-- 联系人 -->
                        {{$t('mx_mobile.Client.1585278139730')}}
                    </div>
                    <control-vue ref="controlVue2" :editSet="editSet1" :optCode="optCode" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :AONIcustSort="AONIcustSort" :currency="currency"></control-vue>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import controlVue from '../CustPublic/optVue/vue/index.vue'
import loadingNew from '../CustPublic/LoadingVue/index.vue'
import { mapGetters, mapMutations } from 'vuex'
import { ImagePreview } from 'vant'
import { getIsApp } from '@/libs/utils'

export default {
    name: 'BusinessCard',
    title: function fc() { return this.$t('mx_mobile.Client.1585548055236') }, // '名片扫描'
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            msg: 'BusinessCard',
            imgUrl: '',
            custData: {},
            itemData: {},
            loading: false,
            initState: false,
            submitLoading: false,
            optCode: 'otNew',
            editSet: [],
            editSet1: [],
            editSet2: [],
            mustFidldName01: ['custName', 'custAddr', 'web'],
            mustFidldName02: ['mailAddress', 'mobile', 'tel', 'deptName', 'jobs'],
            moduleCode: 'BF001',
            defaultImg: 'this.src="/images/noData/noCustomer.png"',
            isHave: false,
            custId: '',
            rightFlag: true
            // moduleCode: ''
        }
    },
    created() {
        // this.moduleCode = this.$route.params.moduleCode
    },
    mounted() {
        this.setMenu()
        this.getEditSet()
    },
    methods: {
        ...mapMutations({
            set_closeYes: 'SET_CLOSEYES'
        }),
        checkPhone(phone) {
            let trim = (str, isglobal) => {
                var result
                result = str.replace(/(^\s+)|(\s+$)/g, '')
                if (isglobal && isglobal.toLowerCase() === 'g') {
                    result = result.replace(/\s/g, '')
                }
                return result
            }
            phone = trim(phone, 'g')
            // var reg = /^(\+?|0?|86?|086?)?1[356789]\d{9}$/
            var reg = /^(\+?)?[0-9]*$/
            return !!reg.test(phone)
        },
        showImg() {
            let list = []
            list.push(this.imgUrl)
            ImagePreview({
                images: list,
                startPosition: 0
            })
        },
        async onRead(file) {
            try {
                this.upLoad(file)
                this.custData = {}
                this.imgUrl = file.content
                let url = this.Global.config.apiBaseURL + this.Global.api.client.cardscan.client_cardscan
                let fd = new FormData()

                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true, // 禁用背景点击
                    loadingType: 'spinner',
                    message: this.$t('mx_mobile.Client.1585548196944') // 压缩中。。。
                })

                /*  let size = file.file.size
                 let scale = 1
                 if (size > 500000) {
                     scale = parseFloat((500000 / size).toFixed(2))
                 } */
                // const fileBlob = await this.Global.utils.imageUtils.compress(file.file, 0.7)
                const fileBlob = getIsApp() ? file.file : await this.Global.utils.imageUtils.compress(file.file, 0.7)
                this.$toast.clear()

                fd.append('file', fileBlob)
                this.loading = true
                this.axios.post(url, fd).then((res) => {
                    this.loading = false
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        this.custData = this.analyzeScanData(res.data.data)
                        this.transData()
                    } else {
                        this.$toast.fail(this.$t('mx_mobile.Client.1585548149477'))// '图片未识别到信息，请重试！'
                    }
                }, (res) => {
                    this.loading = false
                    this.$toast.fail(this.$t('mx_mobile.Client.1585548149477'))// 图片解析接口异常，请重试
                })
            } catch (e) {
                this.cardImagesId = ''
                alert(e)
            }
        },
        upLoad(file) {
            let files = []
            files.push(file.file)
            let data = {
                url: this.Global.config.uploadUrl,
                FileList: files,
                onprogress: (event) => { // 上传进度
                },
                onloadstart: (event) => { // 开始上传
                },
                onload: (res) => { // 上传成功
                    if (res.code.toString() === this.Global.config.RES_OK) {
                        this.cardImagesId = res.data || ''
                    } else {
                        this.cardImagesId = ''
                        this.$toast.fail(res.msg)
                    }
                }
            }
            this.Global.utils.fileUpload(data)
        },
        getCustData() {
            let custData = this.Global.utils.getItem('businessCardData')
            if (custData) {
                this.custData = JSON.parse(this.Global.utils.getItem('businessCardData'))
            } else {
                this.custData = {}
            }
            this.Global.utils.removeItem('businessCardData')
            this.transData()
        },
        transData() {
            if (this.custData.custName) {
                this.checkCust(this.custData.custName, this.custData)
            } else if (Object.keys(this.custData).length != 0) {
                this.isHave = false
                this.updates(this.custData)
            }
        },
        updates(data) {
            this.$nextTick(() => {
                if (this.isHave) {
                    this.$refs.controlVue.updateCard(data)
                } else {
                    this.$refs.controlVue1.updateCard(data)
                    this.$refs.controlVue2.updateCard(data)
                }
            })
        },
        async checkCust(custName, obj = null) {
            let _this = this
            let data = {
                moduleCode: 'BF001',
                order: 'desc',
                from: '0',
                size: '1',
                searchType: 'allList',
                'custName.keyword': custName
            }
            let res = await _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_generalOperate_get, { params: data }).catch(() => {
                _this.itemData = {}
                _this.submitLoading = false
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
            if (res.data.code.toString() === _this.Global.config.RES_OK) {
                if (res.data.data.totalNum > 0) {
                    _this.rightFlag = true
                    _this.custId = res.data.data.list[0].custId
                    _this.isHave = false
                    _this.initState = true
                    if (obj) {
                        obj.custId = res.data.data.list[0].custId
                        _this.updates(obj)
                    }
                    // _this.$toast.success(_this.$t('mx_mobile.Client.1585548114539'))// '客户已存在'
                    _this.itemData = res.data.data.list[0]
                } else {
                    _this.rightFlag = false
                    _this.isHave = false
                    _this.initState = true
                    if (obj) {
                        _this.updates(obj)
                    }
                    _this.itemData = {}
                }
            } else {
                _this.isHave = false
                _this.initState = true
                _this.updates(obj)
                _this.$toast.fail(res.data.msg)
                _this.itemData = {}
            }
        },
        submitAddFrom() { // 提交新增客户数据
            this.$nextTick(() => {
                if (this.isHave) {
                    let data = this.$refs.controlVue.submit()
                    if (data) {
                        this.submitAddBF003(this.checkBe(data))
                    }
                } else {
                    let data1 = this.$refs.controlVue1.submit()
                    let data2 = this.$refs.controlVue2.submit()
                    if (data1 && data2) {
                        if (this.cardImagesId != '') {
                            data2.cardImagesId = []
                            data2.cardImagesId.push(this.cardImagesId)
                        }
                        console.log('data1', data1)
                        if (data1.custRepeatType) {
                            this.$dialog.alert({
                                title: this.$t('mx_mobile.Client.1585304004347'), // '提示'
                                message: `<span style="font-weight: bolder;">“${data1.custName}”</span>${this.$t('mx_mobile.common.1665975439222')}`, // 客户已存在，您可以
                                confirmButtonText: this.$t('mx_mobile.common.1665975597155'), // '仅新增联系人'
                                showCancelButton: true
                            }).then(async () => {
                                await this.checkCust(data1.custName)
                                if (this.rightFlag) {
                                    this.addNewContact(data1, data2)
                                } else {
                                    this.$toast.fail(this.$t('mx_mobile.common.1665975641439')) // '暂无权限对该客户操作！'
                                }
                            })
                        } else {
                            this.submitAdd(this.checkBe(data1), this.checkBe(data2))
                        }
                    }
                }
            })
        },
        addNewContact(data1, data2) {
            this.rightCheck(this.custId, data2)
        },
        // 权限校验
        rightCheck(custId, data2) {
            data2.custId = custId
            let params = {
                moduleCode: 'BF003',
                optCode: this.optCode,
                identFieldValue: custId
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_rightCheck_do
            this.axios.get(url, { params: params })
                .then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        this.submitAddBF003(this.checkBe(data2))
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
        },
        checkBe(obj) {
            if (!(typeof obj == 'object')) {
                return
            }
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && (obj[key] == null || obj[key] == undefined || obj[key] == '')) {
                    delete obj[key]
                }
            }
            return obj
        },
        submitAddBF003(data) {
            let _this = this
            data['moduleCode'] = 'BF003'
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.getFormKey((formKey, msg) => {
                if (formKey && formKey != '') {
                    data.formKey = formKey
                    _this.axios.post(this.Global.config.apiBaseURL + _this.Global.api.v2.document_generalOperate_add, data).then(function (res) {
                        if (res.data.code.toString() == _this.Global.config.RES_OK) {
                            _this.$toast.success(res.data.msg)
                            _this.set_closeYes(false)
                            _this.$router.back()
                        } else {
                            _this.$toast.fail(res.data.msg)
                        }
                        _this.submitLoading = false
                    }, function (res) {
                        _this.submitLoading = false
                        _this.$toast.fail(_this.Global.config.errorTitle)
                    })
                } else {
                    _this.$toast.clear()
                    _this.$toast.fail(msg)
                }
            })
        },
        submitAdd(data1, data2) {
            let _this = this
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.getFormKey((formKey, msg) => {
                if (formKey && formKey != '') {
                    _this.axios.post(this.Global.config.apiBaseURL + _this.Global.api.v2.customerWithContact_add, {
                        'customer': data1,
                        'custContact': data2,
                        'formKey': formKey
                    }).then(function (res) {
                        _this.$toast.clear()
                        if (res.data.code.toString() == _this.Global.config.RES_OK) {
                            _this.$toast.success(res.data.msg)
                            _this.set_closeYes(false)
                            _this.$router.back()
                        } else {
                            _this.$toast.fail(res.data.msg)
                        }
                    }, function (res) {
                        _this.$toast.clear()
                        _this.$toast.fail(_this.Global.config.errorTitle)
                    })
                } else {
                    _this.$toast.clear()
                    _this.$toast.fail(msg)
                }
            })
        },
        getEditSet() { // 获取数据
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF001',
                        type: 'addEditSet'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(_this.swidchEditSet(res.data.data, _this.mustFidldName01))
                    } else {
                        _this.loading = false
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.loading = false
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            var p2 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF003',
                        type: 'addEditSet'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        _this.editSet2 = _this.swidchEditSet(res.data.data, _this.mustFidldName02)
                        let list = []
                        res.data.data.forEach(element => {
                            if (element.fieldName != 'custId') {
                                list.push(element)
                            }
                        })
                        resolve(_this.swidchEditSet(list, _this.mustFidldName02))
                    } else {
                        _this.loading = false
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.loading = false
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2]).then(function (results) {
                _this.editSet = results[0]
                _this.editSet1 = results[1]
                _this.loading = false
                _this.initState = true
            })
        },
        swidchEditSet(list, list1) {
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                if (element.isNotNull == 1 || list1.indexOf(element.fieldName) != '-1') {
                    element.controlData = ''
                    if (element.editState == '0' || element.editState == '2') {
                        element.disabled = true
                    }
                    element.fieldValue = element.inDefaultValue
                    if (element.fieldGroup !== 0) { // 判断是否是一个组
                        let isHave = false
                        let thisGroup = ''
                        groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                            if (item == element.fieldGroup) {
                                isHave = true
                                thisGroup = item // 记住这个组id
                            }
                        })
                        if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                            let newObj = {
                                fieldGroup: element.fieldGroup,
                                cnFieldCaption: '',
                                isTop: element.isTop,
                                group: []
                            }
                            newObj.group.push(element)
                            newLList.push(newObj)
                            groupFirst.push(element.fieldGroup)
                        } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                            newLList.forEach(function (them) {
                                if (them.fieldGroup == thisGroup) {
                                    them.group.push(element)
                                }
                            })
                        }
                    } else {
                        newLList.push(element)
                    }
                }
            })
            return newLList
        },
        setMenu() {
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585548055236'))// 名片扫描
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763'))// 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        async scanning() {
            this.custData = {}
            this.imgUrl = ''
            this.$refs.vanUploader.$refs.input.click()
        },
        /* 扫描结果提取 */
        analyzeScanData(data) {
            if (Object.keys(data).length <= 0) return
            let Obj = {
                custAddr: '',
                web: '',
                custName: '',
                deptName: '',
                mobile: '',
                tel: '',
                mailAddress: '',
                contName: '',
                jobs: ''
            }
            if (data['address'] && this.Global.utils.isArray(data['address'])) {
                let address = data['address'][0]['item']
                let country = address['country'] ? address['country'] : ''
                let region = address['region'] ? address['region'] : ''
                let locality = address['locality'] ? address['locality'] : ''
                let street = address['street'] ? address['street'] : ''
                Obj['custAddr'] = country + region + locality + street
            }
            if (data['url'] && this.Global.utils.isArray(data['url'])) {
                if (data['url'].length > 0) { Obj['web'] = data['url'][0]['item'] }
            }
            if (data['organization'] && this.Global.utils.isArray(data['organization'])) {
                let org = data['organization']
                /* 优先取中文 没有取到随便取 */
                let pattern = new RegExp('.*[\u4e00-\u9fa5]+.*$')

                let newOrg = org.filter(item => {
                    pattern.lastIndex = 0
                    return item['item']['name'] && pattern.test(item['item']['name'])
                })

                if (newOrg.length > 0) {
                    Obj['custName'] = newOrg[0]['item']['name']
                } else {
                    let temp = org.filter(item => item['item']['name'])
                    if (temp.length > 0) {
                        Obj['custName'] = temp[0]['item']['name']
                    }
                }

                /* 部门 */
                let deptName = org.filter(item => item['item']['unit'] && pattern.test(item['item']['unit']))
                if (deptName.length > 0) {
                    Obj['deptName'] = deptName[0]['item']['unit']
                } else {
                    let tempDeptName = org.filter(item => item['item']['unit'])
                    if (tempDeptName.length > 0) {
                        Obj['deptName'] = tempDeptName[0]['item']['unit']
                    }
                }
            }

            if (data['telephone'] && this.Global.utils.isArray(data['telephone'])) {
                let telArr = data['telephone']

                let mobile = telArr.filter(item => item['item']['type'].includes('cellular'))
                if (mobile.length > 0) {
                    Obj['mobile'] = mobile[0]['item']['number']
                }

                /* 电话 */
                let tel = telArr.filter(item => !this.checkPhone(item['item']['number']) && (!item['item']['type'].includes('facsimile')))
                if (tel.length > 0) {
                    Obj['tel'] = tel[0]['item']['number']
                }

                let fax = telArr.filter(item => (!this.checkPhone(item['item']['number'])) && item['item']['type'].includes('facsimile'))
                if (fax.length > 0) {
                    Obj['fax'] = fax[0]['item']['number']
                }
            }
            /* 邮件 */
            if (data['email'] && this.Global.utils.isArray(data['email'])) {
                if (data['email'].length > 0) {
                    Obj['mailAddress'] = [data['email'][0]['item']]
                }
            }
            /* 人员名称 */
            if (data['formatted_name'] && this.Global.utils.isArray(data['formatted_name'])) {
                if (data['formatted_name'].length > 0) {
                    Obj['contName'] = data['formatted_name'][0]['item']
                }
            }
            /* 职位 */
            if (data['title'] && this.Global.utils.isArray(data['title'])) {
                let title = data['title']
                let pattern = new RegExp('.*[\u4e00-\u9fa5]+.*$')
                let jobs = title.filter(item => pattern.test(item['item']))
                if (jobs.length > 0) {
                    Obj['jobs'] = jobs[0]['item']
                } else {
                    Obj['jobs'] = title[0]['item']
                }
            }
            return Obj
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else if (this.closeYes) {
            this.$dialog.confirm({
                title: this.$t('mx_mobile.Client.1585304004347'), // 提示
                message: this.$t('mx_mobile.Client.1585304000139'), // '已编辑内容将不会保存，是否确定离开？'
                closeOnPopstate: false
            }).then(() => {
                this.initState = false
                this.imgUrl = ''
                this.custData = {}
                next()
            }).catch(() => {
                next(false)
            })
        } else {
            this.set_closeYes(true)
            next()
        }
    },
    computed: {
        ...mapGetters(['closeYes'])
    },
    watch: {
        $route(val, old) {
            if (val.path.indexOf('/client/businessCard') != -1) {
                this.setMenu()
                this.getCustData()
            } else {
                if (this.initState) {
                    this.initState = false
                }
            }
        }
    },
    components: {
        'control-vue': controlVue,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
